import styled, { css } from "styled-components";
import { Card } from "@/components/shared-ui/Card/styled";

export const ServiceTargets = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
  ${Card} {
    min-width: 0;
  }
`;

export const PageHeading = styled.h1`
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const ServiceTargetsContent = styled.div`
  h4 {
    margin: 0;
    ${(props) => props.theme.media.large} {
      font-size: 0.95rem;
      line-height: 1.125rem;
    }
  }

  h3 {
    font-size: 1rem;
    line-height: 1.25rem;

    ${(props) => props.theme.media.large} {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.875rem;
  overflow-x: auto;

  ${(props) => props.theme.media.large} {
    overflow: visible;
  }
`;

export const AreaTable = styled.div<{ isSuccessArea?: boolean }>`
  ${(props) =>
    props.isSuccessArea &&
    css`
      tbody tr:not(:first-child) td:first-child {
        margin-left: 15px;
        display: block;
      }
    `}
`;

export const AreaRow = styled.div`
  background: ${(props) => props.theme.gradients.light2};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  ${(props) => props.theme.media.large} {
    padding-left: 29px;
    margin: auto -29px;
  }
`;
