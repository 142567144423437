import * as Styled from "./styled";
import Page from "@/components/layout/Page";
import useCompany from "@/contexts/CompanyContext";
import DashboardCustomers from "./DashboardCustomers";
import DashboardProspecting from "./DashboardProspecting";

const Dashboard = () => {
  const { status } = useCompany();

  const isCustomer = status === "Live" || status === "Onboarding";

  return (
    <Page title="Dashboard" isDashboard>
      <Styled.Dashboard>
        {isCustomer ? (
          <DashboardCustomers />
        ) : (
          <DashboardProspecting />
        )}
      </Styled.Dashboard>
    </Page>
  );
};

export default Dashboard;
