import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import * as Styled from "./styled";
import { ReactComponent as Cross } from "@assets/icons/cross-thin.svg";
import { ReactComponent as ChevronRight } from "@assets/icons/chevron-right.svg";
import { ReactComponent as ArrowLeft } from "@assets/icons/arrow-left.svg";
import Button from "@/components/shared-ui/Button";
import { useEffect, useMemo, useState } from "react";
import {
  addCustomerSpecificLinks,
  hideNavItem,
} from "../../shared/nav.utils";
import { primaryMenu, type NavMenu } from "../../shared/nav.constants";
import useCompany from "@/contexts/CompanyContext";

type Props = {
  openNav: boolean;
  closeNav: () => void;
  openEditModal: () => void;
  isAdmin?: boolean;
};

const ExpandedMobileNav = ({
  openNav,
  closeNav,
  openEditModal,
  isAdmin,
}: Props) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { status, companySpecificLinks } = useCompany();

  const isAdminUser =
    user && user["https://customer.seccl.tech/roles"].includes("admin");

  // Update the navMenu with the customer specific links from useCompany context
  const updatedNavMenu = useMemo(() => {
    return addCustomerSpecificLinks(companySpecificLinks, primaryMenu);
  }, [companySpecificLinks]);

  const [menu, setMenu] = useState<NavMenu[]>([]);
  const [secondaryMenu, setSecondaryMenu] = useState<NavMenu>();

  const resetMenus = () => {
    setMenu(updatedNavMenu);
    setSecondaryMenu(undefined);
  };

  useEffect(() => {
    setMenu(updatedNavMenu);
  }, [updatedNavMenu]);

  return (
    <>
      <Styled.HamburgerMenu data-testid="userWidget" />
      {isAuthenticated && user && (
        <Styled.Dropdown
          open={openNav}
          secondaryMenu={secondaryMenu !== undefined}
        >
          <Styled.NavActions>
            {secondaryMenu && !isAdmin && (
              <Styled.BackButton
                onClick={(e) => {
                  e.stopPropagation();
                  resetMenus();
                }}
              >
                <ArrowLeft /> Back
              </Styled.BackButton>
            )}
            <Styled.CloseSection>
              <Cross
                onClick={(e) => {
                  e.stopPropagation();
                  resetMenus();
                  closeNav();
                }}
              />
            </Styled.CloseSection>
          </Styled.NavActions>
          {!isAdmin && (
            <>
              {secondaryMenu && (
                <Styled.DropdownSection $withBackground>
                  <Styled.DropdownSubMenuHeading>
                    <span>{secondaryMenu.title}</span>
                    {secondaryMenu.description}
                  </Styled.DropdownSubMenuHeading>
                </Styled.DropdownSection>
              )}
              {menu.map((item) => {
                if (
                  item.hidden ||
                  hideNavItem(
                    user["https://customer.seccl.tech/roles"],
                    item.roleGuard,
                    item.statusGuard,
                    status
                  )
                )
                  return null;
                if (item.secondary) {
                  return (
                    <Styled.DropdownItem
                      key={item.title}
                      onClick={(e) => {
                        e.stopPropagation();
                        item.secondary && setMenu(item.secondary);
                        setSecondaryMenu(item);
                      }}
                      submenu
                    >
                      {item.title} <ChevronRight />
                    </Styled.DropdownItem>
                  );
                } else if (item.to) {
                  return (
                    <Styled.DropdownItem
                      key={item.title}
                      as={Link}
                      to={item.to || ""}
                      onClick={resetMenus}
                    >
                      {item.icon && <item.icon />}
                      {item.title}
                      {item.external && <Styled.ExternalLink />}
                    </Styled.DropdownItem>
                  );
                }
                return null;
              })}
            </>
          )}
          {(!secondaryMenu || isAdmin) && (
            <>
              <Styled.DropdownSection $withBackground>
                <Styled.DropdownHeading>Logged in as:</Styled.DropdownHeading>
                <strong>{user.name}</strong>
                <Styled.DropdownLightText>
                  {user.email}
                </Styled.DropdownLightText>
                <Button
                  label="Edit profile"
                  type="button"
                  variant="tertiary"
                  onClick={openEditModal}
                />
              </Styled.DropdownSection>
              {isAdminUser && (
                <Styled.DropdownItem
                  as={Link}
                  to={isAdmin ? "/" : "/admin"}
                  data-testid="viewAdminPortalButton"
                >
                  {isAdmin ? "View customer hub" : "View admin portal"}
                </Styled.DropdownItem>
              )}
              <Styled.LogoutSection>
                <Button label="Contact us" internalLink="/contact" />
                <Button
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                  label="Logout"
                  noArrow
                  inverse
                />
              </Styled.LogoutSection>
            </>
          )}
        </Styled.Dropdown>
      )}
    </>
  );
};

export default ExpandedMobileNav;
