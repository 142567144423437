import Card from "@/components/shared-ui/Card";
import * as Styled from "./styled";
import Button from "@/components/shared-ui/Button";
import ContactCard from "@/components/shared-ui/ContactCard";
import useCompany from "@/contexts/CompanyContext";
import SalesWelcomeCard from "../WelcomeCards/SalesWelcomeCard";

const DashboardProspecting = () => {
  const { company_name } = useCompany();

  return (
    <>
      <Card
        gridColumn="1 / -1"
        noPadding
        featureBackground={{ full: true, gradient: "light2" }}
      >
        <SalesWelcomeCard companyName={company_name} />
      </Card>

      <Card
        gridColumn={ "1 / 3"}
        featureBackground={{ gradient: "light2" }}
      >
        <Styled.CardContent>
          <Styled.CardHeading>Process documentation</Styled.CardHeading>
          <p>
            Find detailed information on the operational and technical processes
            involved in launching and running a platform on Seccl’s technology.
          </p>
        </Styled.CardContent>
        <Button
          data-testid="processDocsButton"
          variant="secondary"
          internalLink="/docs"
          label="All documents"
          centered
        />
      </Card>

      <Card gridColumn="1">
        <Styled.CardHeading>Due diligence</Styled.CardHeading>
        <p>
          Carry out your due diligence on Seccl with the help of our
          comprehensive FAQ library.
        </p>
        <Button
          variant="tertiary"
          internalLink="due-diligence"
          label="See documents"
        />
      </Card>

      <Card gridColumn={["1", "2"]}>
        <Styled.CardHeading>
          How to use our professional portal
        </Styled.CardHeading>
        <p>
          {
            "Watch our series of bitesize training videos to find detailed, step-by-step instructions on how to carry out key actions within our 'Professional UI'."
          }
        </p>
        <Button
          variant="tertiary"
          internalLink="system-training"
          label="See training videos"
        />
      </Card>

      <ContactCard />
    </>
  );
};

export default DashboardProspecting;
