import Card from "@/components/shared-ui/Card";
import styled from "styled-components";

export const HubHighlights = styled(Card)`
  position: relative;
`;

export const TrainingHighlightImageFull = styled.img`
  width: 80%;
  margin: 2rem auto -29px;

  ${(props) => props.theme.media.medium} {
    display: none;
  }
`;

export const TrainingHighlightImagePartial = styled.img`
  display: none;

  ${(props) => props.theme.media.extraLarge} {
    display: block;
    position: absolute;
    width: 360px;
    right: 0;
    bottom: 0;
    margin: 0;
  }
`;

export const HighlightCard = styled(Card)`
  > div {
    justify-content: space-between;
    padding: 0 0.9rem;
  }
  p {
    margin: 0.25rem 0;
    font-size: 0.938rem;
    line-height: 1.375rem;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 1rem;
  grid-template-columns: 1fr;

  ${(props) => props.theme.media.medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.media.extraLarge} {
    grid-template-columns: 1.25fr 1.25fr 1fr;
  }
`;
