import { Link } from "react-router-dom";
import * as Styled from "./styled";
import type { NavMenu } from "../../shared/nav.constants";
import { hideNavItem } from "../../shared/nav.utils";

type Props = {
  item: NavMenu;
  userRoles: string[];
  companyStatus: string;
  children: React.ReactNode;
};

const DynamicNavLink = ({ item, userRoles, companyStatus, children }: Props) => {
  if (item.hidden || !item.to || hideNavItem(userRoles, item.roleGuard, item.statusGuard, companyStatus))
    return;

  if (item.external) {
    return (
      <Styled.DropdownItem
        as="a"
        href={item.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </Styled.DropdownItem>
    );
  }

  return (
    <Styled.DropdownItem as={Link} to={item.to}>
      {children}
    </Styled.DropdownItem>
  );
};

export default DynamicNavLink;
