import { useCallback, useLayoutEffect, useRef } from "react";

const useClickOutside = (action: (...args: Array<never>) => void) => {
  const elements = useRef<Set<HTMLElement>>(new Set());

  const addElement = (el: HTMLElement | null) => {
    if (el) elements.current.add(el);
  };

  // Function to remove the clickOutside functionality if needed,
  // for instance if an element is disabled for any reason
  const removeElement = (el: HTMLElement | null) => {
    if (el) elements.current.delete(el);
  };

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const isOutside = Array.from(elements.current).every(
        (el) => !el.contains(target)
      );

      if (isOutside) {
        action();
      }
    },
    [action]
  );

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return { addElement, removeElement };
};

export default useClickOutside;
