import type { Company } from "@/services/api/types";
import {
  type CompanyStatus,
  type NavMenu,
  type UserRoles,
} from "./nav.constants";

export const hideNavItem = (
  userRoles: string[],
  roleGuard: UserRoles | undefined,
  statusGuard?: CompanyStatus | undefined,
  companyStatus?: string
) => {
  if (
    (!roleGuard && !statusGuard) ||
    (roleGuard && userRoles.includes(roleGuard)) ||
    statusGuard === companyStatus
  ) {
    return false;
  }
  return true;
};

export const addCustomerSpecificLinks = (
  links: Company["companySpecificLinks"],
  navMenu: NavMenu[]
) => {
  const updateMenuItem = (item: NavMenu): NavMenu => {
    // Check if the airtableLabel matches a key in links
    if (item.airtableLabel && links[item.airtableLabel as keyof typeof links]) {
      return {
        ...item,
        to: links[item.airtableLabel as keyof typeof links],
      };
    }

    // If the item has a secondary property, process it recursively
    if (item.secondary) {
      return {
        ...item,
        secondary: item.secondary.map(updateMenuItem),
      };
    }

    // Return the item unchanged if no match or secondary items
    return item;
  };

  // Process the top-level navMenu array
  return navMenu.map(updateMenuItem);
};
