import React, { type ReactNode } from "react";

type ConditionalWrapperProps = {
  condition: boolean | undefined;
  wrapper: (children: ReactNode) => JSX.Element;
  children: ReactNode;
};

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? wrapper(children) : <>{children}</>;
};

export default ConditionalWrapper;
