import styled, { css } from "styled-components";

export const Header = styled.div<{ $alt?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  height: 5rem;
  width: 100%;
  background: ${(props) =>
    props.$alt ? props.theme.gradients.dark3 : props.theme.gradients.dark1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  color: ${(props) => props.theme.colors.lightest};
`;

export const HeaderContent = styled.div`
  height: 100%;
  margin: auto;
  width: calc(100% - 2rem);
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderUtilitySection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    height: auto;
  }
`;

export const Heading = styled.h1`
  margin: 0;
`;

export const UserWidget = styled.div`
  position: relative;
  svg {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
    color: ${(props) => props.theme.colors.highlight2};
    display: inline-block;
  }
  cursor: pointer;
  padding-right: 1rem;
`;

export const Dropdown = styled.div<{ open: boolean }>`
  cursor: auto;
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: ${(props) => props.theme.colors.lightest};
  color: ${(props) => props.theme.colors.dark};
  flex-direction: column;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.medium};
  margin-top: 1rem;
  width: max-content;
  z-index: 7;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent transparent
      ${(props) => props.theme.colors.lightest} transparent;
  }
`;

export const DropdownSection = styled.div<{ $withBackground?: boolean }>`
  padding: 0 1.75rem 0.875rem 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${(props) =>
    props.$withBackground &&
    css`
      background: ${props.theme.gradients.light2};
      margin: 0.875rem;
      padding: 0.875rem;
    `}
`;

export const DropdownHeading = styled.span`
  display: block;
  font-size: 0.875rem;
  line-spacing: 1;
  color: ${(props) => props.theme.colors.accent1};
`;

export const DropdownLightText = styled.span`
  display: block;
  font-weight: 300;
`;

export const DropdownItem = styled.div`
  padding: 1rem 1.75rem;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.colors.light};
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border: none;
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.light};
  }
`;
