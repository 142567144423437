import styled from "styled-components";
import { Card } from "@/components/shared-ui/Card/styled";

export const KeyContacts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
  ${Card} {
    min-width: 0;
  }
`;

export const PageHeading = styled.h1`
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const ServiceSummaryContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  font-size: 0.875rem;
  ${(props) => props.theme.media.medium} {
    grid-template-columns: max-content 1fr;
    gap: 2rem;
  }
`;

export const FieldValuesWrapper = styled.div<{ isEscalationPoint?: boolean }>`
  display: flex;
  gap: ${(props) => (props.isEscalationPoint ? "1rem 6rem" : "0.5rem 1rem")};
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const FieldValue = styled.span`
  display: block;
  justify-self: start;
  ${(props) => props.theme.media.medium} {
    white-space: nowrap;
  }
`;

export const HighlightedFieldValue = styled.span<{ isStatus?: boolean }>`
  background: ${(props) =>
    props.isStatus ? props.theme.colors.accent2 : props.theme.gradients.light2};
  color: ${(props) =>
    props.isStatus ? props.theme.colors.lightest : props.theme.colors.darkest};
  padding: 0 0.8rem;
  border-radius: 0.25rem;
  display: block;
  justify-self: start;
  ${(props) => props.theme.media.medium} {
    white-space: nowrap;
  }
`;

export const Detail = styled.span`
  font-weight: 500;
`;
