import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import "instantsearch.css/themes/satellite.css";
import * as Styled from "./styled";
import {
  InstantSearch,
  Highlight,
  Configure,
  useHits,
  Index,
} from "react-instantsearch";
import type { SearchClient } from "algoliasearch";
import { useEffect, useState } from "react";
import useClickOutside from "@/hooks/useClickOutside";
import { Link } from "react-router-dom";
import config from "@/config";
import FlexContainer from "../FlexContainer";
import { ReactComponent as AlgoliaLogo } from "@assets/algolia-logo.svg";
import type { AlgoliaIndexNames } from "api/services/algolia";
import ConditionalWrapper from "@/components/utility/ConditionalWrapper";
import { useMediaQuery } from "@/hooks/useMatchMedia";

export type SearchIndexTypes = "docs" | "resources" | "dd" | "updates";

const Search = ({
  indexPriority,
  expanded,
}: {
  indexPriority?: SearchIndexTypes;
  expanded?: boolean;
}) => {
  const algoliaClient = algoliasearch(
    config.ALGOLIA_APP_ID,
    config.ALGOLIA_SEARCH_API_KEY
  );

  // Proxy client to make empty searches return 0 hits.
  const searchClient = {
    ...algoliaClient,
    // eslint-disable-next-line
    search(requests: any) {
      if (
        requests.every(
          ({ params }: { params: { query: string } }) => !params.query
        )
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: "",
            params: "",
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  return (
    <FlexContainer marginLeft="2rem" marginRight="1rem">
      <InstantSearch
        searchClient={searchClient as SearchClient}
        indexName="process_docs"
      >
        <Configure hitsPerPage={4} />
        <CustomSearch indexPriority={indexPriority} expanded={expanded} />
      </InstantSearch>
    </FlexContainer>
  );
};

const CustomSearch = ({
  indexPriority,
  expanded,
}: {
  indexPriority?: SearchIndexTypes;
  expanded?: boolean;
}) => {
  const isDesktop = useMediaQuery("medium");
  const [active, setActive] = useState(false);
  const [numberOfDocHits, setNumberOfDocHits] = useState(0);
  const [numberOfResourceHits, setNumberOfResourceHits] = useState(0);
  const [numberOfDdHits, setNumberOfDdHits] = useState(0);
  const [numberOfUpdateHits, setNumberOfUpdateHits] = useState(0);
  const { addElement } = useClickOutside(() => setActive(false));

  const indexes: {
    indexName: AlgoliaIndexNames;
    type: SearchIndexTypes;
    updater: React.Dispatch<React.SetStateAction<number>>;
    priority?: boolean;
  }[] = [
    {
      indexName: "process_docs",
      type: "docs",
      updater: setNumberOfDocHits,
      priority: indexPriority === "docs",
    },
    {
      indexName: "shared_resources",
      type: "resources",
      updater: setNumberOfResourceHits,
      priority: indexPriority === "resources",
    },
    {
      indexName: "due_diligence",
      type: "dd",
      updater: setNumberOfDdHits,
      priority: indexPriority === "dd",
    },
    {
      indexName: "updates",
      type: "updates",
      updater: setNumberOfUpdateHits,
      priority: indexPriority === "updates",
    },
  ];
  // If index has priority, move to front of array
  indexes.sort((a, b) => (a.priority ? -1 : b.priority ? 1 : 0));

  return (
    <ConditionalWrapper
      condition={expanded}
      wrapper={(children) => (
        <Styled.ExpandableSearchBox $active={active}>
          {children}
        </Styled.ExpandableSearchBox>
      )}
    >
      <div ref={(el) => addElement(el)}>
        <Styled.CustomSearchBox
          $active={active}
          $expanded={expanded}
          onFocus={() => setActive(true)}
          placeholder={isDesktop ? "Search by topic" : "Search"}
        />
        <Styled.Search $active={active}>
          <Styled.Hits $active={active}>
            {numberOfDocHits > 0 ||
            numberOfResourceHits > 0 ||
            numberOfUpdateHits > 0 ||
            numberOfDdHits > 0 ? (
              <Styled.ResultsBar>Results</Styled.ResultsBar>
            ) : (
              <Styled.NoHits>No results</Styled.NoHits>
            )}
            {indexes.map((index) => (
              <Index key={index.indexName} indexName={index.indexName}>
                <CustomHits type={index.type} updateNumHits={index.updater} />
              </Index>
            ))}
            <Styled.LogoWrapper>
              <AlgoliaLogo />
            </Styled.LogoWrapper>
          </Styled.Hits>
        </Styled.Search>
      </div>
      {active && <Styled.Background />}
    </ConditionalWrapper>
  );
};

const CustomHits = ({
  type,
  updateNumHits,
}: {
  type: SearchIndexTypes;
  updateNumHits: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { hits } = useHits();

  useEffect(() => {
    updateNumHits(hits.length);
  }, [hits, updateNumHits]);

  const renderCategory = (type: string) => {
    switch (type) {
      case "docs":
        return "Process docs";
      case "resources":
        return "Resources";
      case "dd":
        return "Due diligence";
      case "updates":
        return "Updates";
      default:
        return "Other";
    }
  };

  return hits.map((hit) => (
    <Link to={`/${hit.path}`} key={hit.objectID}>
      <Styled.Hit>
        <Styled.HitWrapper>
          <div>
            <h2>
              <Highlight attribute="title" hit={hit} />
            </h2>
            <h3>
              <Highlight
                attribute={
                  type === "resources" || type === "updates"
                    ? "description"
                    : "section"
                }
                hit={hit}
              />
            </h3>
          </div>
          <Styled.HitCategory>{renderCategory(type)}</Styled.HitCategory>
        </Styled.HitWrapper>
      </Styled.Hit>
    </Link>
  ));
};

export default Search;
