import styled from "styled-components";

export const DropdownItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2px;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  max-width: 290px;
  height: max-content;
  font-weight: 300;
  line-height: 1.25rem;
  padding: 0.6rem 0.75rem;

  &:hover {
    background: ${(props) => props.theme.colors.light};
    border-radius: 4px;
  }
`;

