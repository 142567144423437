import { useAuth0 } from "@auth0/auth0-react";
import * as Styled from "./styled";
import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";
import { useState } from "react";
import useClickOutside from "@/hooks/useClickOutside";
import EditProfile from "../EditProfile";
import Button from "@/components/shared-ui/Button";
import Search from "@/components/shared-ui/Search";
import { useMediaQuery } from "@/hooks/useMatchMedia";
import type { SearchIndexTypes } from "@/components/shared-ui/Search/Search";
import useCompany from "@/contexts/CompanyContext";

type Props = {
  isAdmin?: boolean;
  searchIndexPriority?: SearchIndexTypes;
};

const CompactHeader = ({ isAdmin, searchIndexPriority }: Props) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { company_name, firm_id } = useCompany();
  const isDesktop = useMediaQuery("large");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { addElement } = useClickOutside(() => setDropdownOpen(false));

  const isAdminUser =
    user && user["https://customer.seccl.tech/roles"].includes("admin");

  return (
    <>
      {isDesktop && !isAdmin ? (
        <Search indexPriority={searchIndexPriority} />
      ) : null}
      <Styled.HeaderUtilitySection>
        {isAuthenticated && user && (
          <Styled.UserWidget
            ref={(el) => addElement(el)}
            onClick={() => setDropdownOpen((prev) => !prev)}
            data-testid="userWidget"
          >
            <strong>{company_name}</strong>
            <Chevron />
            <Styled.Dropdown open={dropdownOpen}>
              <Styled.DropdownSection $withBackground>
                <Styled.DropdownHeading>Logged in as:</Styled.DropdownHeading>
                <strong>{user.name}</strong>
                <Styled.DropdownLightText>
                  {user.email}
                </Styled.DropdownLightText>
                <Button
                  label="Edit profile"
                  type="button"
                  variant="tertiary"
                  onClick={() => setModalOpen(true)}
                />
              </Styled.DropdownSection>
              <Styled.DropdownSection>
                <Styled.DropdownHeading>Company:</Styled.DropdownHeading>
                <strong>{company_name}</strong>
                {firm_id && (
                  <Styled.DropdownLightText>{firm_id}</Styled.DropdownLightText>
                )}
              </Styled.DropdownSection>
              {isAdminUser && (
                <Styled.DropdownItem
                  as={Link}
                  to={isAdmin ? "/" : "/admin"}
                  data-testid="viewAdminPortalButton"
                >
                  {isAdmin ? "View customer hub" : "View admin portal"}
                </Styled.DropdownItem>
              )}
              <Styled.DropdownItem
                onClick={() =>
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  })
                }
              >
                Logout
              </Styled.DropdownItem>
            </Styled.Dropdown>
          </Styled.UserWidget>
        )}
        {isDesktop && !isAdmin ? (
          <Button label="Contact us" internalLink="/contact" inverse />
        ) : null}
      </Styled.HeaderUtilitySection>
      {isAuthenticated && (
        <EditProfile
          modalActive={modalOpen}
          toggleModal={() => setModalOpen((prev) => !prev)}
        />
      )}
    </>
  );
};

export default CompactHeader;
