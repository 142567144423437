import type { AxiosInstance } from "axios";
import type {
  AirtableSLATableData,
  AirtableTaskView,
  CustomerAccountData,
  AllUsersResponse,
  Auth0Role,
  Auth0User,
  Auth0UserCreate,
  Auth0UserUpdate,
  Company,
  Documentation,
  DueDiligence,
  DueDiligenceFile,
  SystemTraining,
  Update,
  UpdateAvailableMonth,
  User,
  Roadmap,
  AmysUpdateID,
} from "./types";

export const api = {
  init: (client: AxiosInstance) => ({
    user: {
      getUserInfo: async () => {
        const res = await client.get<User>(`getUser`);
        return res.data;
      },
      changePassword: async () => {
        const res = await client.post(`changePassword`);
        return res.data;
      },
      updateUserName: async (name: User["name"]) => {
        const res = await client.patch<User>(`updateUserName`, { name: name });
        return res.data;
      },
    },
    utility: {
      downloadAsset: async (fileId: string) => {
        const res = await client.get(`downloadAsset`, {
          params: { id: fileId },
          responseType: "blob",
        });
        return res;
      },
      buildSearch: async () => {
        const res = await client.get(`buildDocsSearch`);
        return res.data;
      },
    },
    company: {
      get: async () => {
        const res = await client.get<Company>(`getCompany`);
        return res.data;
      },
      getConfig: async () => {
        const res = await client.get<CustomerAccountData>(`getCompanyConfig`);
        return res.data;
      },
    },
    documentation: {
      getAll: async () => {
        const res = await client.get<Documentation[]>("getDocs");
        return res.data;
      },
      get: async (docId: string) => {
        const res = await client.get<Documentation>(`getDoc?id=${docId}`);
        return res.data;
      },
    },
    dueDiligence: {
      getAll: async () => {
        const res = await client.get<DueDiligence[]>("getDueDiligence");
        return res.data;
      },
      getFile: async () => {
        const res = await client.get<DueDiligenceFile>("getDueDiligenceFile");
        return res.data;
      },
    },
    update: {
      getAll: async (
        companyStatus: string,
        maxRecords?: number,
        category?: Update["category"],
        month?: UpdateAvailableMonth
      ) => {
        const res = await client.get<Update[]>("getUpdates", {
          params: {
            companyStatus: companyStatus,
            maxRecords: maxRecords,
            category: category,
            month: month?.date_month,
            year: month?.date_year,
          },
        });
        return res.data;
      },
      getAvailableMonths: async (companyStatus: string) => {
        const res = await client.get<UpdateAvailableMonth[]>(
          "getUpdatesAvailableMonths",
          { params: { companyStatus: companyStatus } }
        );
        return res.data;
      },
      get: async (companyStatus: string, updateId: string) => {
        const res = await client.get<Update>("getUpdate", {
          params: { companyStatus: companyStatus, id: updateId },
        });
        return res.data;
      },
      getLatestRoadmapUpdate: async () => {
        const res = await client.get<AmysUpdateID[]>("getLatestRoadmapUpdate");
        return res.data;
      },
    },
    roadmap: {
      getAll: async () => {
        const res = await client.get<Roadmap[]>("getRoadmap");
        return res.data;
      },
    },
    airtableTask: {
      getAll: async (hideCompleted = false, maxRecords?: number) => {
        const res = await client.get<AirtableTaskView>(
          `getAirtableTaskView?hideCompleted=${
            hideCompleted ? "true" : ""
          }&maxRecords=${maxRecords}`
        );
        return res.data;
      },
    },
    airtableSLAs: {
      getAll: async () => {
        const res = await client.get<AirtableSLATableData>("getAirtableSLAs");
        return res.data;
      },
    },
    systemTraining: {
      getAll: async () => {
        const res = await client.get<SystemTraining[]>("getSystemTraining");
        return res.data;
      },
    },
    admin: {
      user: {
        getAll: async (
          page: number,
          companyFilter?: string,
          search?: string
        ) => {
          const res = await client.get<AllUsersResponse>(`getUsers`, {
            params: {
              page: page,
              companyId: companyFilter,
              search: search,
            },
          });
          return res.data;
        },
        get: async (userId: string) => {
          const res = await client.get<Auth0User>(`getUser?id=${userId}`);
          return res.data;
        },
        update: async (data: Auth0UserUpdate) => {
          const res = await client.patch<Auth0User>(`updateUser`, data);
          return res.data;
        },
        create: async (data: Auth0UserCreate) => {
          const res = await client.post<Auth0User>(`createUser`, data);
          return res.data;
        },
        delete: async (userId: string) => {
          const res = await client.delete<Auth0User>(`deleteUser?id=${userId}`);
          return res.data;
        },
      },
      roles: {
        getAll: async () => {
          const res = await client.get<Auth0Role[]>(`getRoles`);
          return res.data;
        },
      },
      company: {
        getAll: async () => {
          const res = await client.get<Company[]>("getCompanies");
          return res.data;
        },
      },
    },
  }),
};

export type ApiType = ReturnType<(typeof api)["init"]>;
