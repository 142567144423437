import { SearchBox } from "react-instantsearch";
import styled, { css } from "styled-components";

export const Search = styled.div<{ $active?: boolean }>`
  position: relative;
  display: ${(props) => (props.$active ? "block" : "none")};
`;

export const ResultsBar = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.accent1};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const NoHits = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.accent1};
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Hits = styled.ul<{ $active?: boolean }>`
  display: ${(props) => (props.$active ? "block" : "none")};
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.lightest};
  box-shadow: 0px 0px 10px 0px rgb(29, 23, 57, 0.3);
  position: absolute;
  top: 0rem;
  border-radius: 0 0 8px 8px;
  left: 0;
  z-index: 2;
  width: 100%;
  color: ${(props) => props.theme.colors.dark};
  overflow-y: auto;
  max-height: 90vh;
`;

export const Hit = styled.li`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  cursor: pointer;
  h2 {
    font-size: 1rem;
    margin: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 4px 4px;
    border: 0;
  }
  &:hover {
    background: ${(props) => props.theme.colors.light};
  }
  .ais-Highlight-highlighted {
    background: ${(props) => props.theme.colors.highlight2};
    color: ${(props) => props.theme.colors.dark};
  }
`;

export const HitWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HitCategory = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.accent1};
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex-shrink: 0;
`;

export const ExpandableSearchBox = styled.div<{
  $active?: boolean;
}>`
  position: ${(props) => (props.$active ? "absolute" : "relative")};
  width: 100%;
  max-width: 460px;
  top: 0;
  left: 0;

  ${(props) => props.theme.media.medium} {
    left: ${(props) => (props.$active ? "6.25rem" : "0")};
  }

  ${(props) => props.theme.media.large} {
    max-width: 674px;
  }
`;

export const CustomSearchBox = styled(SearchBox)<{
  $active?: boolean;
  $expanded?: boolean;
}>`
  position: relative;
  z-index: 7;
  .ais-SearchBox-form {
    background: transparent;
    width: 400px;
    max-width: 460px;
    &::before {
      color: blue;
    }

    ${(props) =>
      props.$expanded &&
      css`
        width: ${props.$active ? "100%" : "111px"};

        ${props.theme.media.medium} {
          width: ${props.$active ? "460px" : "185px"};
          max-width: 674px;
        }
        ${props.theme.media.large} {
          width: ${props.$active ? "674px" : "185px"};
          max-width: 674px;
        }
      `}
  }
  .ais-SearchBox-input {
    background: ${(props) => (props.$active ? "white" : "transparent")};
    color: ${(props) =>
      props.$active ? props.theme.colors.dark : props.theme.colors.light};
    box-shadow: none;
    border: 1px solid white;
    border-radius: ${(props) => (props.$active ? "4px 4px 0 0" : "4px")};
    &::placeholder {
      color: ${(props) => props.theme.colors.utility};
    }
  }
  .ais-SearchBox-resetIcon {
    height: 10px;
    width: 10px;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: ${(props) => props.theme.colors.dark};
  opacity: 70%;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  svg {
    color: darkgray;
    width: 80px;
  }
`;
