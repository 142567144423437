import { useState, type SyntheticEvent, type ReactNode } from "react";
import * as Styled from "./styled";
import { ReactComponent as Chevron } from "@assets/icons/arrow-down.svg";
import { ReactComponent as CheckboxChecked } from "@assets/icons/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "@assets/icons/checkbox-unchecked.svg";
import useClickOutside from "@/hooks/useClickOutside";

type Props = {
  id: string;
  label: string;
  values: string[];
  onValueChange: (selectedValues: string[]) => void;
  options: { label: string | ReactNode; value: string; disabled?: boolean }[];
  alt?: boolean;
  compact?: boolean;
  customTitleText?: string;
};

const MultiSelectDropdown = ({
  id,
  label,
  options,
  alt,
  values,
  onValueChange,
  compact,
  customTitleText,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { addElement } = useClickOutside(() => setIsOpen(false));

  const handleSelection = (value: string, e: SyntheticEvent) => {
    e.stopPropagation();
    if (values.includes(value)) {
      const updatedVals = [...values].filter((o) => o != value);
      onValueChange(updatedVals);
    } else {
      onValueChange([...values, value]);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Label htmlFor={id} $compact={compact}>
        {label}
      </Styled.Label>
      <Styled.MultiSelectDropdown
        id={id}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((prev) => !prev);
        }}
        ref={(el) => addElement(el)}
      >
        <Styled.TitleContainer $active={isOpen} $alt={alt}>
          {customTitleText || `${values.length} active`} <Chevron />
        </Styled.TitleContainer>
        <Styled.OptionsContainer $active={isOpen} $alt={alt}>
          {options.map((option) => (
            <Styled.Option
              key={option.value}
              $alt={alt}
              onClick={(e: SyntheticEvent) => handleSelection(option.value, e)}
              $disabled={option.disabled}
            >
              {values.includes(option.value) || option.disabled ? (
                <CheckboxChecked />
              ) : (
                <CheckboxUnchecked />
              )}
              {option.label}
            </Styled.Option>
          ))}
        </Styled.OptionsContainer>
      </Styled.MultiSelectDropdown>
    </Styled.Wrapper>
  );
};

export default MultiSelectDropdown;
