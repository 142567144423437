import { Card } from "@/components/shared-ui/Card/styled";
import styled from "styled-components";

export const Configuration = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
  padding-bottom: 1rem;
  ${Card} {
    min-width: 0;
  }
`;

export const PageHeading = styled.h1`
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

export const CompanySummaryWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  div {
    flex-grow: 1;
  }
  ${(props) => props.theme.media.medium} {
    flex-wrap: wrap;
  }
`;

export const CompanySummaryCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
  ${(props) => props.theme.media.medium} {
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 40px;
  width: auto;
  display: block;
  margin: 0 auto;
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ServiceSummaryContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  font-size: 0.875rem;
  ${(props) => props.theme.media.medium} {
    grid-template-columns: max-content 1fr;
    gap: 2rem;
  }
`;

export const FieldValuesWrapper = styled.div`
  display: flex;
  gap: 0.5rem 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const FieldValue = styled.span`
  background: ${(props) => props.theme.gradients.light2};
  padding: 0 0.8rem;
  border-radius: 0.25rem;
  display: block;
  justify-self: start;
  ${(props) => props.theme.media.medium} {
    white-space: nowrap;
  }
`;

export const Detail = styled.span`
  font-weight: 500;
`;
