import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import * as Styled from "./styled";
import { Spinner } from "../../../components/shared-ui/LoadingScreen/styled";
import Button from "@/components/shared-ui/Button";
import ErrorScreen from "@/components/shared-ui/ErrorScreen";
import FlexContainer from "@/components/shared-ui/FlexContainer";
import useCompany from "@/contexts/CompanyContext";

const UpdatesCard = () => {
  const api = useApi();
  const { status: companyStatus } = useCompany();

  const {
    data: updates,
    isLoading,
    isError,
  } = useQuery(
    ["get-updates"],
    () => api.update.getAll(companyStatus, 4),
    { useErrorBoundary: false } // We have a custom error for this (below)
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorScreen withoutPage />;
  }

  if (!updates.length) {
    return (
      <FlexContainer paddingTop="1rem">
        No updates available right now.
      </FlexContainer>
    );
  }

  return (
    <Styled.Wrapper data-testid="updatesCard">
      <Styled.Fade>
        {updates.map((update) => (
          <Styled.UpdateItem key={update.id}>
            <Styled.UpdateTitle $category={update.category}>
              {update.title}
            </Styled.UpdateTitle>
            <span>{new Date(update.date).toLocaleDateString("en-GB")}</span>
            <Styled.Badge>{update.category}</Styled.Badge>
          </Styled.UpdateItem>
        ))}
      </Styled.Fade>
      <Button variant="secondary" internalLink="/updates" label="See all" />
    </Styled.Wrapper>
  );
};

export default UpdatesCard;
