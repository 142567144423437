import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { useQuery } from "@tanstack/react-query";
import useApi from "@/contexts/ApiContext";
import { Link } from "react-router-dom";
import { useState } from "react";
import type { Update, UpdateAvailableMonth } from "@/services/api/types";
import { Badge } from "../Dashboard/UpdatesCard/styled";
import useCompany from "@/contexts/CompanyContext";

const UpdatesList = () => {
  const api = useApi();
  const { status: companyStatus } = useCompany();
  const [categoryFilter, setCategoryFilter] = useState<Update["category"]>();
  const [monthFilter, setMonthFilter] = useState<UpdateAvailableMonth>();
  const isLiveCustomer = companyStatus === "Live";

  const { data: updates } = useQuery(
    ["get-updates", companyStatus, categoryFilter, monthFilter],
    () =>
      api.update.getAll(companyStatus, undefined, categoryFilter, monthFilter),
    { keepPreviousData: true }
  );
  const { data: availableMonths } = useQuery(
    ["get-updates-available-months", companyStatus],
    () => api.update.getAvailableMonths(companyStatus)
  );

  if (!updates || !availableMonths) {
    return <LoadingScreen />;
  }

  const categories: Update["category"][] = [
    "Release notes",
    "General notification",
    ...(isLiveCustomer ? ["Urgent notification" as const] : []),
    "Roadmap update",
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <Page title="Updates" searchIndexPriority="updates">
      <Styled.UpdatesList>
        <Card noPadding>
          <Styled.Heading>Important updates</Styled.Heading>
          <Styled.Layout>
            <Styled.List>
              {updates.length < 1 && (
                <span>No results matching these filters</span>
              )}
              {updates.map((item) => (
                <Link key={item.id} to={`/updates/${item.id}`}>
                  <Styled.ListItem $category={item.category}>
                    <Styled.ListItemTitle>
                      <strong>{item.title}</strong>
                      <Badge>{item.category}</Badge>
                    </Styled.ListItemTitle>
                    <Styled.Date>
                      {new Date(item.date).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      })}
                    </Styled.Date>
                    <span>{item.description}</span>
                  </Styled.ListItem>
                </Link>
              ))}
            </Styled.List>
            <Styled.FilterBar>
              <Styled.FilterHeading>Filter</Styled.FilterHeading>
              <Styled.FilterSectionHeading>
                By category
              </Styled.FilterSectionHeading>
              {categories.map((category) => (
                <Styled.FilterItem
                  key={category}
                  $category={category}
                  $active={categoryFilter === category}
                  onClick={() =>
                    setCategoryFilter((prev) =>
                      prev === category ? undefined : category
                    )
                  }
                >
                  {category}
                </Styled.FilterItem>
              ))}
              <Styled.FilterSectionHeading>
                By month
              </Styled.FilterSectionHeading>
              {[...availableMonths].reverse().map((month) => (
                <Styled.FilterItem
                  key={`${month.date_month}-${month.date_year}`}
                  $active={month === monthFilter}
                  onClick={() =>
                    setMonthFilter((prev) =>
                      prev === month ? undefined : month
                    )
                  }
                >
                  {months[month.date_month - 1]} {month.date_year}
                </Styled.FilterItem>
              ))}
            </Styled.FilterBar>
          </Styled.Layout>
        </Card>
      </Styled.UpdatesList>
    </Page>
  );
};

export default UpdatesList;
